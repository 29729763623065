import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <p>
  The project uses version 5.15.3 of the FontAwesome library. <a className='pink' href='https://fontawesome.com/icons?d=gallery' target='_blank'>See all icons here</a>
    </p>
    <h2 {...{
      "id": "squid-icon"
    }}>{`Squid Icon`}</h2>
    <p>{`There is an extra icon, which is the Squid logo:`}</p>
    <i className="fasq fa-squid mr-2" style={{
      "fontSize": "25px"
    }}></i>
    <i className="fasq fa-squid pink mr-2" style={{
      "fontSize": "25px"
    }}></i>
    <i className="fasq fa-squid lilac" style={{
      "fontSize": "25px"
    }}></i>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<i class="fasq fa-squid pink"></i>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      